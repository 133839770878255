import React, { useContext } from 'react';
import { Callout } from 'components';
// import { Row, Col } from 'react-flexbox-grid';
import { appContext } from 'providers/appProvider';
import DownloadBlock from 'components/DownloadBlock';
import ColorBlock from 'components/Content/ColorBlock';

function DownloadNDHCPEnrollmentForm({ className, ...props }) {
  const { appConfigs } = useContext(appContext);
  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };
  return (
    <Callout className={`${className}`}>
      <ColorBlock lightorange id="colorblock" className="mb-20">
        <DownloadBlock
          DownloadFile="NS-05705_Nutrition_Direct_Enrollment_Form_Digital.pdf"
          LinkCaption="DOWNLOAD ENROLLMENT FORM"
          ThumbnailImage="Nutrition_Direct_enrollment_form_thumb.png"
          className="enrollment-form-btn yellow-button"
        >
          <h2 className="txt-uppercase">
            <span
              className="brandname-nascobal"
              dangerouslySetInnerHTML={{
                __html: brandNames.NUTRITION,
              }}
            />{' '}
            HOME DELIVERY ENROLLMENT FORM
          </h2>
          <p>Easy enrollment for physicians and patients</p>
          <ol className="no-bold">
            <li>Patient completes top half of form.</li>
            <li>Prescriber signs preprinted prescription at bottom of form.</li>
            <li>Office faxes completed form to specialty pharmacy.</li>
          </ol>
        </DownloadBlock>
      </ColorBlock>
    </Callout>
  );
}

export default DownloadNDHCPEnrollmentForm;
