import React, { useState, useContext, useEffect } from 'react';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import { Grid, Row, Col } from 'react-flexbox-grid';

import SourceEmitter from 'libs/emitter';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import ContentBlock from 'components/Content/ContentBlock';
import VideoContainer from 'components/VideoContainer';
import DownloadNDHCPEnrollmentForm from '../../../codes/components/callout-contents/ndhcp/DownloadNDHCPEnrollmentForm';
import JulieJohannesVideo from '../../../codes/components/callout-contents/ndpat/JulieJohannesVideo';

import NDHCPPracticeResourcesHeroMobile from '../../../assets/images/ND-HCP-practice-resources-mobile.png';
import videoThumb from '../../../assets/images/nutrition-hcp-logo.png';
import NutritionDirectVideos from '../../../codes/libs/videos/nutrition-direct-videos';

import './practice-resources.scss';

let Subscription = null;

const practiceresourcesPage = () => {
  const { appConfigs } = useContext(appContext);
  const [directLinkVideo, setDirectLinkVideo] = useState(false);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionHcp;
  // const metaData = appConfigs?.metaData?.nutritionHcp;

  // const brandNames = {
  //   NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
  //   BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
  //   NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  // };

  const setResponseParams = () => {
    const responseObj = window.location.hash
      .substr(1)
      .split('&')
      .map(el => el.split('='))
      .reduce((pre, cur) => {
        pre[cur[0]] = cur[1];
        return pre;
      }, {});
    setDirectLinkVideo(responseObj.video);

    if (responseObj.physicianLocator) {
      SourceEmitter.emit('SpecialistLocatorTrigger', 'open');
    }
  };

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    setResponseParams();
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const pageTitle = 'NUTRITION DIRECT™ | Practice Resources';
  const pageDescription = 'Download helpful resources for your practice';

  const renderPatientResources = () => {
    return (
      <div>
        <ContentBlock id="enrollment-and-faqs-block">
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>PRACTICE RESOURCES</h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={NDHCPPracticeResourcesHeroMobile} />
                </div>
              </Col>
            </Row>
            <Row id="colorblock-nd-hcp-enrollment-forms">
              <Col xs={12}>
                <DownloadNDHCPEnrollmentForm className="color-block-lightorange" />
              </Col>
            </Row>
            <Row id="colorblock-nd-patient-enrollment-julie-video">
              <Col xs={12}>
                <JulieJohannesVideo className="color-block-lightorange" />
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
        <VideoContainer
          poster={videoThumb}
          url="https://fast.wistia.net/embed/iframe/jzbz9tj4os?videoFoam=true"
          smallThumb
          carousel
          directLink={directLinkVideo === 'ndvideo1'}
          videoId="nd-pre-operative-video"
          dlURL=""
          video={NutritionDirectVideos[3]}
        />
      </div>
    );
  };

  const renderPage = () => {
    return (
      <div id="nutrition-hcp-practice-resources">
        {renderPatientResources()}
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout
      indication={indication}
      className="nutrition-hcp-practice-resources-page"
    >
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default practiceresourcesPage;
